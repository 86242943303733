/* Basic styles for tiptap editor and preview template  */

.ProseMirror p,
.previewTemplate p {
  margin: 1em 0;
}

.ProseMirror a:not([role='button']),
.previewTemplate a:not([role='button']) {
  color: theme('colors.primary.default');
}

.ProseMirror ul,
.previewTemplate ul {
  list-style: disc;
  padding-left: 16px;
}

.ProseMirror ul ul,
.previewTemplate ul ul {
  list-style: circle;
}

.ProseMirror ul ul ul,
.previewTemplate ul ul ul {
  list-style: square;
}

.ProseMirror ol,
.previewTemplate ol {
  list-style: decimal;
  padding-left: 16px;
}

.ProseMirror ol ol,
.previewTemplate ol ol {
  list-style: lower-roman;
}

.ProseMirror-focused:focus {
  outline: none;
}
