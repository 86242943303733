/**
 * Normalize.css and sTailwind's base styles.
 */

@import-normalize;

@import './tailwind-base-copy.css';

/**
 * Custom base styles, applied after the tailwind-base classes
 */

html {
  font-size: 62.5%;
  font-family: Roboto, Helvetica, Arial, sans-serif;
  background-color: #1c1c1c;
}

body {
  font-size: 14px;
  line-height: 1.4;
  overflow-x: hidden;
}

html,
body,
#root {
  position: relative;
  margin: 0;
  min-height: 100vh;
}

h1,
.h1 {
  font-size: 24px;
}

h2,
.h2 {
  font-size: 20px;
}

h3,
.h3 {
  font-size: 16px;
}

h4,
.h4 {
  font-size: 15px;
}

h5,
.h5 {
  font-size: 13px;
}

h6,
.h6 {
  font-size: 12px;
}

.ps > .ps__rail-y,
.ps > .ps__rail-x {
  z-index: 99;
}

a[role='button'] {
  text-decoration: none;
}

[role='tooltip'] {
  z-index: 9999;
}

/* Medium Devices, Desktops Only */
@media only screen and (min-width: 992px) {
  ::-webkit-scrollbar {
    width: 8px;
    height: 8px;
    background-color: rgba(0, 0, 0, 0);
  }

  ::-webkit-scrollbar:hover {
    width: 8px;
    height: 8px;
    background-color: rgba(0, 0, 0, 0.06);
  }

  ::-webkit-scrollbar-thumb {
    border: 2px solid transparent;
    border-radius: 20px;
  }

  ::-webkit-scrollbar-thumb:active {
    border-radius: 20px;
  }
}

form label {
  z-index: 99;
}

body.no-animate *,
body.no-animate *::before,
body.no-animate *::after {
  transition: none !important;
  animation: none !important;
}

button:focus {
  outline: none;
}

@keyframes autofill {
  0%,
  100% {
    background: transparent;
  }
}

input:-webkit-autofill {
  animation-delay: 1s;
  animation-name: autofill !important;
  animation-fill-mode: both;
  -webkit-box-shadow: 0 0 0 100px transparent inset !important;
}

:focus {
  outline-color: transparent;
}

/* 'focus-visible' polyfill adds 'data-focus-visible-added' attribute on keyboard focus
    This will hide the focus indicator if the element receives focus via the mouse,
    but it will still show up on keyboard focus.
*/
:focus:not([data-focus-visible-added]) {
  outline: none;
}

/* BEGIN: Override FuseLayout's link color */
a:not([role='button']) {
  color: theme('colors.primary.default');
}
a.text-current:not([role='button']) {
  color: currentColor;
}
a.link,
a:not([role='button'])[target='_blank'] {
  background: transparent;
}
a:focus {
  outline-color: theme('colors.primary[300]');
}
/* END: Override FuseLayout's link color */

/* For PowerBI Artifact Container */
.analytics {
  height: 100%;
  margin: 1% auto;
  width: 100%;
}
